// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  columns: {
    firstName: 'First name',
    lastName: 'Last name',
    profilePictureLink: "Profile Picture Link",
    licenseNumber: 'License N°',
    validUntil: 'Valid until',
    autoclub: 'Autoclub',
    bloodType: 'Blood type',
    licenseCategory: 'License category',
    user: "User",
    email: "Email",
    role: "Role",
    category: "Category",
    function: "Function",
    personalNumber: "Personal N°",
    name: "Name",
    authorizedPerson: "Authorized person",
    city: "City",
    members: "Members",
    profilePicture: "Profile picture",
    password: "Password",
    forgotPassword: "Forgot password?",
    login: "Login",
    confirmPassword: "Confirm password",
    healthCertificate: "Health Certificate",
    birthday: "Birthday",
    image: 'Image',

    federation: 'Federation of Autosport of Kosovo',
    autoclubsList: 'Autoclubs list',
    internationalsList: 'International licenses list',
    nationalsList: 'National licenses list',
    officialsList: 'Official licenses list',
    dragList: 'Drag licenses list',
    userList: 'Users list',

    nameRequired: "Name is required.",
    authorizedPersonRequired: "Authorized person is required.",
    cityRequired: "City is required.",
    membersRequired: "Members is required.",
    paymentCertificate: "Payment Certificate of Quotation",

    firstNameRequired: "First name is required.",
    lastNameRequired: "Last name is required.",
    emailRequired: "Email is required.",
    validEmail: "Email must be a valid email address.",
    profilePictureRequired: "Profile picture is required.",
    roleRequired: "Role is required.",
    birthdayRequired: "Birthday is required.",
    passwordRequired: 'Password is required.',

    userRequired: "User is required.",
    categoryRequired: "Category is required.",
    functionRequired: "Function is required.",
    licenceNumberRequired: "License number is required.",
    personalNumberRequired: "Personal number is required.",
    // validUntilRequired: "Valid until is required.",

    autoclubRequired: "Autoclub is required.",
    bloodTypeRequired: "Blood type is required.",
    licenceCategoryRequired: "License category is required.",

    oldPassRequired: "Old Password is required.",
    newPassRequired: "New Password is required.",
    passLength: "Password must be at least 6 characters.",
    passMin: "Password must be minimum 6+.",
    passMatch: "Passwords must match.",
    imageRequired: "Image is required."
  },

  content: {
    dashboard: "Dashboard",
    welcome: "Welcome",
    to: "to",
    licensingCompliance: "Licensing compliance and management of competitors and officials in one single dashboard!",
    getGoing: "Get Going",
    forgotPasswordTitle: "Forgot your password",
    forgotPasswordDescription: " Please enter the email address associated with your account and We will email you a link to reset your password.",
    returnSignIn: "Return to sign in",
    sendRequest: "Send Request",
    requestTitle: "Request sent successfully!",
    sentSubTitle: "We've sent a link to your email.",
    pleaseCheckYour: "Please check your email and reset the password by clicking on that link",
    dontHaveLink: "Don’t have a link?",
    resendLink: "Resend link",

    permissionDenied: "Permission Denied",
    doesNotHaveAccess: "You do not have permission to access this page",

    whatFaskDashboard: "What Fask dashboard",
    helpsYou: "helps you?",
    licenseManagement: "License Management",
    complianceMonitoring: "Compliance Monitoring",
    reportingAndAnalytics: "Reporting and Analytics",
    cardSectionOne: "This section allows users to view and edit all information related to competitor and official licenses, including expiration dates, payment status, and renewal requests. Users can also issue new licenses and revoke existing licenses.",
    cardSectionTwo: "This section enables users to check compliance with relevant rules and regulations, and to report on any non-compliant activity. Users can also set up automated compliance checks and receive notifications of any potential violations.",
    cardSectionThree: " This section provides users with powerful reporting and analytics tools to help them make data-driven decisions. Users can generate various reports on license activity, compliance, and revenue, and analyze the data to identify trends and patterns that can inform their decision-making.",
    allRightsReserved: "All rights reserved",
    powerdBy: "Powered By",

    home: "Home",
    logout: "Logout",

    registerLicense: "How do race car drivers register for a license?",
    sppedingForm: 'They fill out a "speeding" form.',
    getStarted: "Get started absolutely free.",
    alreadyAccount: "Already have an account?",
    signIn: "Sign In",
    createAccount: "Create Account",
    bySignInAgree: "By signing up, I agree to",
    and: "and",

    welcomeAboard: "Welcome aboard!",
    licenceDescription: "Why did the race car driver need a license dashboard? To keep track of all his lapses in judgment.",

    signInToDashboard: "Sign in to dashboard",
    createAnAccount: "Create an account",

    notFound: "Not Found",
    pageNotFound: "Sorry, page not found!",
    subTitle: "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Besure to check your spelling.",
    goToHome:"Go to Home",
    noResults: "No results found for",
    tryChecking: "Try checking for typos or using complete words.",
    pleaseEnterKeyword: "Please enter keywords",

    list: "List",
    delete: "Delete",
    edit: "Edit",
    create: "Create",
    update: "Update",
    send: "Send",
    saveChanges: "Save changes",
    noData: "No Data",
    clear: "Clear",
    or: "OR",

    account: "Account",
    accountSettings: "Account Settings",
    general: "General",
    settings: "Settings",

    changePassword: "Change Password",
    oldPassword: "Old password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",

    tryAgain: "Something is wrong. Try again!",
    success: "Success!",
    successfully: "Successfully!",

    search: "Search...",
    searchUser: "Search user by name or email...",
    searchAutoClubs: "Search autoclubs by name or city...",
    searchDragPlaceholder: "Search name or license number...",
    searchInternationalPlaceholder: "Search name or license number...",
    searchNationalPlaceholder: "Search name or license number...",
    searchOfficialPlaceholder: "Search name or license number...",

    newUser: "New user",
    createUser: "Create a new user",
    createUserButton: "Create user",
    userList: "User list",
    editUser: "Edit User",

    autoclubs: "Autoclubs",
    newAutoclub: "New autoclub",
    createAutoclub: "Create a new autoclub",
    autoClubList: "Autoclub list",
    editAutoclub: "Edit autoclub",

    officials: "Officials",
    newOfficial: "New official",
    createOfficial: "Create a new official",
    officialList: "Official list",
    editOfficial: "Edit official",

    nationals: "Nationals",
    newNational: "New national",
    createNational: "Create a new national",
    nationalList: "National list",
    editNational: "Edit national",

    internationals: "Internationals",
    newInternational: "New international",
    createInternational: "Create a new international",
    internationalList: "International list",
    editInternational: "Edit international",

    drags: "Drags",
    newDrag: "New drag",
    createDrag: "Create a new drag",
    dragList: "Drag list",
    editDrag: "Edit drag",
  },
  welcome: {
    title: "Welcome to the FASK dashboard!",
    description: "This app allows you to manage all licenses of your competitors and officials in a centralized space. You can view and edit license data, check payment status and manage renewal requests. Additionally, you can monitor and report on any activity related to licenses in one place and ensure compliance with all applicable rules and regulations.",
    button: "Go Now",
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please email us at arber+fask@coetic-x.com`,
    documentation: `documentation`,
  },
  photoUploader: {
    update: 'Update photo',
    upload: 'Upload photo', 
    allowed: 'Allowed',
    max: 'max size of',
    noAccess: `You don't have access to upload profile picture!`,
    wentWrong: 'Something went wrong. Try again!',
  },

  app: `App`,
  user: `User`,
  autoclub: `Autoclub`,
  drag: `Drag license`,
  official: `Official`,
  international: `International license`,
  national: `National license`,
  officials: `Officials`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
