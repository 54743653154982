import { Navigate, useRoutes } from 'react-router-dom';
// hooks
import useRoleBasedAccess from 'src/hooks/useRoleBasedAccess';
import PermissionDeniedLogoutPage from 'src/pages/dashboard/PermissionDeniedLogoutPage';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  ComingSoonPage,
  MaintenancePage,
  // Official
  OfficialListPage,
  OfficialDetailsPage,
  OfficialEditPage,
  OfficialCreatePage,
  // National
  NationalListPage,
  NationalDetailsPage,
  NationalEditPage,
  NationalCreatePage,
  // International
  InternationalListPage,
  InternationalDetailsPage,
  InternationalEditPage,
  InternationalCreatePage,
  // Drag
  DragListPage,
  DragDetailsPage,
  DragEditPage,
  DragCreatePage,
  // Autoclub
  AutoclubListPage,
  AutoclubDetailsPage,
  AutoclubEditPage,
  AutoclubCreatePage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  const { hasRole, isAuthorized } = useRoleBasedAccess(['admin']);

  let routes;

  if (!hasRole) {
    routes = [
      // Auth
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <LoginPage />
              </GuestGuard>
            ),
          },
          {
            path: 'register',
            element: (
              <GuestGuard>
                <RegisterPage />
              </GuestGuard>
            ),
          },
          { path: 'login-unprotected', element: <LoginPage /> },
          { path: 'register-unprotected', element: <RegisterPage /> },
          {
            element: <CompactLayout />,
            children: [
              { path: 'reset-password', element: <ResetPasswordPage /> },
              { path: 'new-password', element: <NewPasswordPage /> },
              { path: 'verify', element: <VerifyCodePage /> },
            ],
          },
        ],
      },

      // PermissionDenied
      {
        path: '*',
        element: (
          <PermissionDeniedLogoutPage />
        ),
      },
      {
        element: <MainLayout />,
        children: [
          { element: <HomePage />, index: true },
          { path: 'about-us', element: <AboutPage /> },
          { path: 'contact-us', element: <Contact /> },
          { path: 'faqs', element: <FaqsPage /> },
        ],
      },

    ]
  } else {
    routes = [
      // Auth
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <LoginPage />
              </GuestGuard>
            ),
          },
          {
            path: 'register',
            element: (
              <GuestGuard>
                <RegisterPage />
              </GuestGuard>
            ),
          },
          { path: 'login-unprotected', element: <LoginPage /> },
          { path: 'register-unprotected', element: <RegisterPage /> },
          {
            element: <CompactLayout />,
            children: [
              { path: 'reset-password', element: <ResetPasswordPage /> },
              { path: 'new-password', element: <NewPasswordPage /> },
              { path: 'verify', element: <VerifyCodePage /> },
            ],
          },
        ],
      },

      // Dashboard
      {
        path: 'dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
          { path: 'app', element: <GeneralAppPage /> },
          {
            path: 'user',
            children: [
              { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
              { path: 'profile', element: <UserProfilePage /> },
              { path: 'cards', element: isAuthorized ? <UserCardsPage /> : <PermissionDeniedPage /> },
              { path: 'list', element: isAuthorized ? <UserListPage /> : <PermissionDeniedPage /> },
              { path: 'new', element: isAuthorized ? <UserCreatePage /> : <PermissionDeniedPage /> },
              { path: ':id/edit', element: isAuthorized ? <UserEditPage /> : <PermissionDeniedPage /> },
              { path: 'account', element: <UserAccountPage /> },
            ],
          },
          {
            path: 'official',
            children: [
              { element: <Navigate to="/dashboard/official/list" replace />, index: true },
              { path: 'list', element: <OfficialListPage /> },
              { path: ':id', element: <OfficialDetailsPage /> },
              { path: ':id/edit', element: isAuthorized ? <OfficialEditPage /> : <PermissionDeniedPage /> },
              { path: 'new', element: isAuthorized ? <OfficialCreatePage /> : <PermissionDeniedPage /> },
            ],
          },
          {
            path: 'national',
            children: [
              { element: <Navigate to="/dashboard/national/list" replace />, index: true },
              { path: 'list', element: <NationalListPage /> },
              { path: ':id', element: <NationalDetailsPage /> },
              { path: ':id/edit', element: isAuthorized ? <NationalEditPage /> : <PermissionDeniedPage /> },
              { path: 'new', element: isAuthorized ? <NationalCreatePage /> : <PermissionDeniedPage /> },
            ],
          },
          {
            path: 'international',
            children: [
              { element: <Navigate to="/dashboard/international/list" replace />, index: true },
              { path: 'list', element: <InternationalListPage /> },
              { path: ':id', element: <InternationalDetailsPage /> },
              { path: ':id/edit', element: isAuthorized ? <InternationalEditPage /> : <PermissionDeniedPage /> },
              { path: 'new', element: isAuthorized ? <InternationalCreatePage /> : <PermissionDeniedPage /> },
            ],
          },
          {
            path: 'drag',
            children: [
              { element: <Navigate to="/dashboard/drag/list" replace />, index: true },
              { path: 'list', element: <DragListPage /> },
              { path: ':id', element: <DragDetailsPage /> },
              { path: ':id/edit', element: isAuthorized ? <DragEditPage /> : <PermissionDeniedPage /> },
              { path: 'new', element: isAuthorized ? <DragCreatePage /> : <PermissionDeniedPage /> },
            ],
          },
          {
            path: 'autoclub',
            children: [
              { element: <Navigate to="/dashboard/autoclub/list" replace />, index: true },
              { path: 'list', element: <AutoclubListPage /> },
              { path: ':id', element: <AutoclubDetailsPage /> },
              { path: ':id/edit', element: isAuthorized ? <AutoclubEditPage /> : <PermissionDeniedPage /> },
              { path: 'new', element: isAuthorized ? <AutoclubCreatePage /> : <PermissionDeniedPage /> },
            ],
          },
          { path: 'permission-denied', element: <PermissionDeniedPage /> },
          { path: 'blank', element: <BlankPage /> },
        ],
      },

      // Main Routes
      {
        element: <MainLayout />,
        children: [
          { element: <HomePage />, index: true },
          { path: 'about-us', element: <AboutPage /> },
          { path: 'contact-us', element: <Contact /> },
          { path: 'faqs', element: <FaqsPage /> },
        ],
      },

      // Errors
      {
        element: <CompactLayout />,
        children: [
          { path: 'coming-soon', element: <ComingSoonPage /> },
          { path: 'maintenance', element: <MaintenancePage /> },
          { path: '500', element: <Page500 /> },
          { path: '404', element: <Page404 /> },
          { path: '403', element: <Page403 /> },
        ],
      },

      // 404
      { path: '*', element: <Navigate to="/404" replace /> },
    ];
  }
  return useRoutes(routes);
}
