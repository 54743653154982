import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API } from '../config-global';
//
import { ActionMapType, AuthStateType, AuthUserType, FirebaseContextType } from './types';


// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<FirebaseContextType | null>(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);
const secondaryFirebaseApp = initializeApp(FIREBASE_API, 'secondary');

export const db = getFirestore(firebaseApp);

// Firebase storage reference
export const storage = getStorage(firebaseApp);

const AUTH = getAuth(firebaseApp);
const SECONDARY_AUTH = getAuth(secondaryFirebaseApp);

const DB = getFirestore(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const GITHUB_PROVIDER = new GithubAuthProvider();

const TWITTER_PROVIDER = new TwitterAuthProvider();

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateUser = useCallback((user: any) => {
    dispatch({
      type: Types.INITIAL,
      payload: {
        isAuthenticated: true,
        user: {
          ...user
        },
      },
    });

  }, [])

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          const profile = docSnap.data();

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                ...profile,
                // role: "admin"
              },
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const signupWithGoogle = useCallback(async () => {
    try {
      await signInWithPopup(AUTH, GOOGLE_PROVIDER).then(async (res) => { 
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        // eslint-disable-next-line no-unsafe-optional-chaining
        const [firstName, lastName] = res.user?.displayName ? res.user?.displayName.split(' ') : [] as any;

        await setDoc(userRef, {
          email: res.user?.email,
          firstName,
          lastName,
          profilePicture: '',
          role: "basic",
          medical: null,
          birthday: null,
        });
      })
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  },[]);

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(AUTH, GOOGLE_PROVIDER);
  }, []);

  const loginWithGithub = useCallback(() => {
    signInWithPopup(AUTH, GITHUB_PROVIDER);
  }, []);

  const loginWithTwitter = useCallback(() => {
    signInWithPopup(AUTH, TWITTER_PROVIDER);
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string, profilePicture: string, medical?: string, birthday?: any) => {
      await createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
          email,
          firstName,
          lastName,
          profilePicture,
          role: "basic",
          medical: medical || null,
          birthday: birthday || null,
        });
      });
    },
    []
  );

  // REGISTER 
  const registerWithoutLogin = useCallback(
    async (email: string, password: string, firstName: string, lastName: string, profilePicture: string, country?: string, phone?: string, birthday?: string, licence?: string) => {
      await createUserWithEmailAndPassword(SECONDARY_AUTH, email, password).then(async (res) => {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
          email,
          firstName,
          lastName,
          profilePicture,
          role: "basic",
          savedCourses: [],
          savedLessons: [],
          savedQuestions: [],
          licence: licence || null,
          medicalLicence: null,
          country: country || null,
          phone: phone || null,
          birthday: birthday || null,
        });
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(() => {
    signOut(AUTH);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      loginWithGoogle,
      signupWithGoogle,
      loginWithGithub,
      loginWithTwitter,
      register,
      registerWithoutLogin,
      logout,
      updateUser,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithGithub,
      loginWithGoogle,
      signupWithGoogle,
      loginWithTwitter,
      register,
      registerWithoutLogin,
      logout,
      updateUser,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
