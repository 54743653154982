import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Container,
  Typography,
  CardHeader,
  Button,
  Link,
} from '@mui/material';
//
import { useAuthContext } from 'src/auth/useAuthContext';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'src/components/snackbar';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import { useSettingsContext } from '../../components/settings';
// auth
import RoleBasedGuard from '../../auth/RoleBasedGuard';


// ----------------------------------------------------------------------

export default function PermissionDeniedLogoutPage() {
  const { themeStretch } = useSettingsContext();
  const [role] = useState('admin');
  const { isAuthenticated, logout } = useAuthContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login);
    } catch (error) {
      console.error('err', error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <Helmet>
        <title> Other Cases: Permission Denied | FASK</title>
      </Helmet>

      <Container sx={{ mt: 6 }} maxWidth={themeStretch ? false : 'lg'}>
        <RoleBasedGuard hasContent roles={[role]}>
          <Box gap={3} display="grid" gridTemplateColumns="repeat(2, 1fr)">
            {[...Array(8)].map((_, index) => (
              <Card key={index}>
                <CardHeader title={`Card ${index + 1}`} subheader="Proin viverra ligula" />

                <Typography sx={{ p: 3, color: 'text.secondary' }}>
                  Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. In enim justo,
                  rhoncus ut, imperdiet a, venenatis vitae, justo. Vestibulum fringilla pede sit
                  amet augue.
                </Typography>
              </Card>
            ))}
          </Box>
        </RoleBasedGuard>

        {isAuthenticated ? 
        <Box sx={{ textAlign: 'center', zIndex: 1000 }}>
          <Button variant='outlined' onClick={handleLogout}>
            Log out
          </Button>
        </Box> :
          <Box sx={{ textAlign: 'center' }}>
            <Link component={RouterLink} to={PATH_AUTH.login} variant="subtitle2">
              Log in
            </Link>
          </Box>
        }

      </Container>
    </>
  );
}
