// @mui
import { Typography, Stack, styled } from '@mui/material';
// components
import Logo from '../../components/logo';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

const ContentStyled = styled(StyledContent)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(18, 8, 0, 8),
  },
}));

type Props = {
  title?: string;
  subtitle?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title, subtitle }: Props) {
  return (
    <StyledRoot>
      <StyledSection>
      <Logo
        sx={{
          zIndex: 9,
          // position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          // ml: { xs: 2, md: 5 },
        }}
      />
        <Typography variant="h3" sx={{ mt: 15, mb: 0, maxWidth: 780, fontWeight: 700, textAlign: 'center' }}>
          {title || 'Hi, Welcome back'}
        </Typography>

        <Typography variant="h4" sx={{ mt: 5, mb: 25, maxWidth: 880, fontWeight: 400, textAlign: 'center' }}>
          {subtitle}
        </Typography>
{/* 
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/illustration_dashboard.png'}
          sx={{ maxWidth: 500 }}
        /> */}


        <StyledSectionBg />
      </StyledSection>

      <ContentStyled>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </ContentStyled>
    </StyledRoot>
  );
}
