import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));
export const UserCardsPage = Loadable(lazy(() => import('../pages/dashboard/UserCardsPage')));
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
);

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));


// DASHBOARD: OFFICIAL
export const OfficialListPage = Loadable(lazy(() => import('../pages/dashboard/OfficialListPage')));
export const OfficialDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/OfficialDetailsPage'))
);
export const OfficialCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/OfficialCreatePage'))
);
export const OfficialEditPage = Loadable(lazy(() => import('../pages/dashboard/OfficialEditPage')));


// DASHBOARD: NATIONAL
export const NationalListPage = Loadable(lazy(() => import('../pages/dashboard/NationalListPage')));
export const NationalDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/NationalDetailsPage'))
);
export const NationalCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/NationalCreatePage'))
);
export const NationalEditPage = Loadable(lazy(() => import('../pages/dashboard/NationalEditPage')));


// DASHBOARD: INTERNATIONAL
export const InternationalListPage = Loadable(lazy(() => import('../pages/dashboard/InternationalListPage')));
export const InternationalDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/InternationalDetailsPage'))
);
export const InternationalCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/InternationalCreatePage'))
);
export const InternationalEditPage = Loadable(lazy(() => import('../pages/dashboard/InternationalEditPage')));


// DASHBOARD: DRAG
export const DragListPage = Loadable(lazy(() => import('../pages/dashboard/DragListPage')));
export const DragDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/DragDetailsPage'))
);
export const DragCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/DragCreatePage'))
);
export const DragEditPage = Loadable(lazy(() => import('../pages/dashboard/DragEditPage')));


// DASHBOARD: AUTOCLUB
export const AutoclubListPage = Loadable(lazy(() => import('../pages/dashboard/AutoclubListPage')));
export const AutoclubDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/AutoclubDetailsPage'))
);
export const AutoclubCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/AutoclubCreatePage'))
);
export const AutoclubEditPage = Loadable(lazy(() => import('../pages/dashboard/AutoclubEditPage')));
