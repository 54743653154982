// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const sq = {
  columns: {
    firstName: 'Emri',
    lastName: 'Mbiemri',
    licenseNumber: 'Numri i Licencës',
    profilePictureLink: "Linku i fotos së profilit",
    validUntil: 'Valide deri më',
    autoclub: 'Auto Klubi',
    bloodType: 'Grupi i Gjakut',
    licenseCategory: 'Kategoria e Licencës',
    licenceNumber: "Numri i Licencës",
    user: "Përdoruesi",
    email: "Email",
    role: "Roli",
    category: "Kategoria",
    function: "Funksioni",
    personalNumber: "Numri Personal",
    name: "Emri",
    authorizedPerson: "Personi i autorizuar",
    city: "Qyteti",
    members: "Anëtarë",
    profilePicture: "Fotoja e profilit",
    password: "Fjalëkalimi",
    forgotPassword: "Keni harruar fjalëkalimin?",
    login: "Kyçu",
    confirmPassword: "Konfirmo fjalëkalimin",
    healthCertificate: "Certifikata Shëndetsore",
    birthday: "Ditëlindja",
    image: 'Fotografia',

    federation: 'Federata e Autosporteve te Kosoves',
    autoclubsList: 'Lista e autoklubeve',
    internationalsList: 'Lista e licensave ndërkombëtare',
    nationalsList: 'Lista e licensave kombëtare',
    officialsList: 'Lista e Zyrtarëve',
    dragList: 'Lista e licensave drag',
    userList: 'Lista e përdorueseve',

    nameRequired: "Emri është i domosdoshëm.",
    authorizedPersonRequired: "Personi i autorizuar është i domosdoshëm.",
    cityRequired: "Qyteti është i domosdoshëm.",
    membersRequired: "Anëtarët janë të domosdoshëm.",
    paymentCertificate: "Vertetim i pageses se kuotizimit",

    firstNameRequired: "Emri është i domosdoshëm.",
    lastNameRequired: "Mbiemri është i domosdoshëm.",
    emailRequired: "Email është i domosdoshëm.",
    validEmail: "Email duhet të jetë email i vlefshëm",
    profilePictureRequired: "Fotoja e profilit është e domosdoshme.",
    roleRequired: "Roli është i domosdoshëm.",
    birthdayRequired: "Ditëllindja është e domosdoshme.",
    passwordRequired: 'Fjalëkalimi është i domosdoshem.',

    userRequired: "Përdoruesi është i domosdoshëm.",
    categoryRequired: "Kategoria është e domosdoshme.",
    functionRequired: "Funksioni është i domosdoshëm.",
    licenceNumberRequired: "Numri i licensës është i domosdoshëm.",
    personalNumberRequired: "Numri personal është i domosdoshëm.",
    // validUntilRequired: "Valid until është i domosdoshëm.",

    autoclubRequired: "Auto klubi është i domosdoshëm.",
    bloodTypeRequired: "Grupi i gjakut është i domosdoshëm.",
    licenceCategoryRequired: "Kategoria e licensës është e domosdoshme.",

    oldPassRequired: "Fjalëkalimi i vjetër është i domosdoshëm.",
    newPassRequired: "Fjalëkalimi i ri është i domosdoshëm.",
    passLength: "Fjalëkalimi duhet të jetë të paktën 6 karaktere.",
    passMin: "Fjalëkalimi duhet të jetë të paktën 6+ karaktere.",
    passMatch: "Fjalëkalimet duhet të përputhen.",
    imageRequired: "Fotografia është e domosdoshme."
  },
  content: {
    dashboard: "Paneli",
    welcome: "Mirësevini",
    to: "në",
    licensingCompliance: "Rregullimi i licensave dhe menaxhimi i konkurrentëve dhe zyrtarëve në një panel të vetëm",
    getGoing: "Filloni",
    forgotPasswordTitle: "Keni harruar fjalëkalimin",
    forgotPasswordDescription: "Ju lutem shkruni email adresen të lidhur me llogarinë tuaj dhe do t'ju dërgojmë një link në email për të rivendosur fjalëkalimin tuaj.",
    returnSignIn: "Kthehu te login",
    sendRequest: "Dergo linkun",
    requestTitle: "Kërkesa është dërguar me sukses!",
    sentSubTitle: "Kemi dërguar një link në emailin tuaj.",
    pleaseCheckYour: "Ju lutemi kontrolloni emailin tuaj dhe rivendosni fjalëkalimin duke klikuar në atë link.",
    dontHaveLink: "Nuk keni pranuar një link?",
    resendLink: "Ridërgoni linkun",

    permissionDenied: "Hyrje e paautorizuar",
    doesNotHaveAccess: "Ju nuk keni leje të hyni në këtë faqe.",

    whatFaskDashboard: "Çfarë ju ndihmon",
    helpsYou: "paneli Fask?",
    licenseManagement: "Menaxhimi i Licencave",
    complianceMonitoring: "Monitorimi i Pajtueshmërisë",
    reportingAndAnalytics: "Raportimi dhe Analizat",

    allRightsReserved: "Të gjitha të drejtat e rezervuara",
    powerdBy: "Mundësuar nga",
    cardSectionOne: "Kjo seksion lejon përdoruesit të shikojnë dhe ndryshojnë të gjitha informacionet lidhur me licencat e konkurrentëve dhe zyrtarëve, duke përfshirë datat e skadimit, statusin e pagesës dhe kërkesat për rinnovim. Përdoruesit gjithashtu mund të lëshojnë licenca të reja dhe të anulojnë licenca ekzistuese.",
    cardSectionTwo: "Kjo seksion mundëson përdoruesve të kontrollojnë pajtueshmërinë me rregullat dhe rregulloret relevante, dhe të raportojnë për çdo aktivitet jo-pajtues. Përdoruesit gjithashtu mund të konfigurojnë kontrollë automatike të pajtueshmërisë dhe të marrin njoftime për çdo shkelje të mundshme.",
    cardSectionThree: "Ky seksion ofron përdoruesve mjete të fuqishme për raportim dhe analizë, për t'u ndihmuar të marrin vendime të bazuar në të dhëna. Përdoruesit mund të gjenerojnë raporte të ndryshme për aktivitetin e licencave, për pajtueshmërinë dhe të ardhurat, dhe të analizojnë të dhënat për të zbuluar tendencat dhe modelet që mund t'i informojnë në marrjen e vendimeve.",

    home: "Home",
    logout: "Dalje",

    welcomeAboard: "Mirë se vini në bord!",
    licenceDescription: "Pse kishte nevojë piloti i makinave të garave për panelin e licensave? Për të mbajtur shënime të të gjitha gabimeve të tij në garë.",

    signInToDashboard: "Kyçu në panel",
    createAnAccount: "Krijo llogari",

    registerLicense: "Si regjistrohen pilotët e garave për një licencë?",
    sppedingForm: 'Ata plotësojnë një formular të "shpejtësisë".',
    getStarted: "Filloni plotësisht falas",
    alreadyAccount: "A keni një llogari tashmë?",
    signIn: "Hyni",
    createAccount: "Krijo Llogari",
    bySignInAgree: "Duke u regjistruar, pajtohem me ",
    and: " dhe ",

    list: "Lista",
    delete: "Fshij",
    edit: "Ndrysho",
    create: "Krijo",
    update: "Përditëso",
    send: "Dërgo",
    saveChanges: 'Ruaj ndryshimet',
    noData: "Nuk ka të dhëna",
    clear: "Fshij",
    or: "OSE",

    notFound: "Nuk u gjet",
    pageNotFound: "Më fal, faqja nuk u gjet!",
    subTitle: "Më fal, nuk mund të gjejmë faqen që po kërkoni. Ndoshta keni shkruar gabimisht URL? Sigurohuni të kontrolloni shkrimin tuaj.",
    goToHome:"Shko tek Homepage",
    noResults: "Rezultate nuk u gjetën për",
    tryChecking: "Provoni të kontrolloni për gabime në shkrim ose të përdorni fjale të plota.",
    pleaseEnterKeyword: "Ju lutem vendosni fjalë kyçe",

    account: "Llogaria",
    accountSettings: "Cilësimet e llogarisë",
    general: "Përgjithësisht",
    settings: "Cilësimet",

    changePassword: "Ndrysho fjalëkalimin",
    oldPassword: "Fjalëkalimi i vjetër",
    newPassword: "Fjalëkalimi i ri",
    confirmNewPassword: "Konfirmo fjalëkalimin e ri",

    tryAgain: "Diçka nuk është në rregull. Provoni përsëri!",
    success: "Sukses!",
    successfully: "Me Sukses!",

    search: "Kërko përdoruesin me emër ose email...",
    searchAutoClubs: "Kërkoni autoklubet sipas emrit ose qytetit...",
    searchDragPlaceholder: "Kërko emrin ose numrin e licensës...",
    searchInternationalPlaceholder: "Kërko emrin ose numrin e licensës...",
    searchNationalPlaceholder: "Kërko emrin ose numrin e licensës...",
    searchOfficialPlaceholder: "Kërko emrin ose numrin e licensës...",

    newUser: "Përdorues i ri",
    createUser: "Krijo një përdorues të ri",
    createUserButton: "Krijo përdoruesin",
    userList: "Lista e përdoruesve",
    editUser: "Ndrysho të dhënat e përdoruesit",

    autoclubs: "Autoklubet",
    newAutoclub: "Autoklub i ri",
    createAutoclub: "Krijo një autoklub të ri",
    autoClubList: "Lista e autoklubeve",
    editAutoclub: "Ndrysho të dhënat e auto klubit",

    officials: "Zyrtarët",
    newOfficial: "Shto nje zyrtarë",
    createOfficial: "Shto një zyrtarë të ri",
    officialList: "Lista zyrtarëve",
    editOfficial: "Ndrysho të dhënat e zyrtarit",

    nationals: "Licensat kombëtare",
    newNational: "License e re",
    createNational: "Shto një licensë kombëtare të re",
    nationalList: "Lista e licensave kombëtare",
    editNational: "Ndrysho të dhënat e licensës",

    internationals: "Licensat ndërkombëtare",
    newInternational: "License e re",
    createInternational: "Shto një licensë ndërkombëtare të re",
    internationalList: "Lista e licensave ndërkombëtare",
    editInternational: "Ndrysho të dhënat e licensës",

    drags: "Licensat drag",
    newDrag: "License e re",
    createDrag: "Shto një licensë drag të re",
    dragList: "Lista e licensave drag",
    editDrag: "Ndrysho të dhënat e licensës",
  },
  welcome: {
    title: "Mirë se vini në panelin e FASK-ut!",
    description: "Ky aplikacion ju lejon të menaxhoni të gjitha licencat e konkurrentëve dhe zyrtarëve tuaj në një hapësirë të centralizuar. Ju mund të shikoni dhe redaktoni të dhëna të licencës, të kontrolloni statusin e pagesës dhe të menaxhoni kërkesat e rinovimit. Gjithashtu, mund të monitoroni dhe raportoni për çdo veprim të lidhur me licencat në një vend dhe të siguroheni që përmbaheni me të gjitha rregullat dhe rregulloret e aplikueshme.",
    button: "Shko Tani",
  },
  docs: {
    hi: `Përshëndetje`,
    description: `Keni nevojë për ndihmë? \n Ju lutemi dërgoni një email në arber+fask@coetic-x.com`,
    documentation: `dokumentacion`,
  },
  photoUploader: {
    update: 'Përditëso foto',
    upload: 'Ngarko foto',
    allowed: 'Lejohet',
    max: 'madhesia maksimale prej',
    noAccess: 'Ju nuk keni qasje për të ngarkuar një foto të profilit!',
    wentWrong: 'Diçka shkoi keq. Provoni përsëri!'
  },

  "app": "Aplikacioni",
  "dashboard": "paneli",
  "user": "Përdoruesi",
  "autoclub": "Auto klubi",
  "official": "Zyrtar/e",
  "national": "Licensa kombëtare",
  "international": "Licensa ndërkombëtare",
  "drag": "Licensa drag",
  "list": "lista",
  "edit": "redakto",
  "shop": "dyqani",
  "blog": "blog",
  "post": "postim",
  "mail": "postë",
  "chat": "bisedë",
  "cards": "kartela",
  "posts": "postime",
  "create": "krijo",
  "kanban": "kanban",
  "general": "Përgjithësisht",
  "banking": "bankar",
  "booking": "rezervim",
  "profile": "profili",
  "account": "llogari",
  "product": "produkti",
  "invoice": "faturë",
  "details": "detaje",
  "checkout": "kontroll",
  "calendar": "kalendar",
  "analytics": "analitikë",
  "e-commerce": "e-tregtisë",
  "management": "menaxhim",
  "menu_level": "nivel menyu",
  "menu_level_2a": "nivel menyu 2a",
  "menu_level_2b": "nivel menyu 2b",
  "menu_level_3a": "nivel menyu 3a",
  "menu_level_3b": "nivel menyu 3b",
  "menu_level_4a": "nivel menyu 4a",
  "menu_level_4b": "nivel menyu 4b",
  "item_disabled": "objekti i çaktivizuar",
  "item_label": "etiketë objekti",
  "item_caption": "legjendë objekti",
  "item_external_link": "link i jashtëm objekti",
  "description": "përshkrim",
  "other_cases": "raste të tjera",
  "item_by_roles": "objekt nga rolet",
  "Only_admin_can_see_this_item": "Vetëm admin mund të shohë këtë objekt"
};

export default sq;
