// @mui
import { Stack, Typography, Box } from '@mui/material';
// translate
import { useLocales } from 'src/locales';
// auth
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import LoginWithSocial from './LoginWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  // const { method } = useAuthContext();
  const { translate } = useLocales();

  return (
    <LoginLayout title={`${translate('content.welcomeAboard')}`} subtitle=
    {`${translate('content.licenceDescription')}`}>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>

        <Box>
          <LanguagePopover />
        </Box>

        <Typography variant="h4">{`${translate('content.signInToDashboard')}`}</Typography>

        {/* <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{`${translate('content.newUser')}`}</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            {`${translate('content.createAnAccount')}`}
          </Link>
        </Stack> */}
        {/* <Tooltip title={method} placement="left">
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}
          />
        </Tooltip> */}
      </Stack>

      {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email: <strong>arber@coetic-x.com</strong> / password:<strong> Shabi123</strong>
      </Alert> */}

      <AuthLoginForm />

      <LoginWithSocial />
    </LoginLayout>
  );
}
